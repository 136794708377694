/*styles that cross the entire site*/
body{
  background-color: #C5C1C0;
}

html, body{
    margin: 0;
    padding: 0;
    width: 100%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.wrapLeft {
  float: left;
  padding: 0 10px;
}

.wrapRight {
  float: right;
  padding: 0 15px;
}

/*==========
Typography
==========*/

/*@font-face {
  font-family: 'Yellowtail', cursive;
  src: url('../storage/fonts/Yellowtail-Regular.ttf');
}*/

h1,h2,h3,h4,h5,h6 {
  font-family: 'Yellowtail', cursive;
}

h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
  color: #333333;
}

h1 a:hover,h2 a:hover,h3 a:hover,h4 a:hover,h5 a:hover,h6 a:hover {
  color: #e47b7b;
}

.name {
  font-weight:bold;
  font-size: 1.5em;
}


/*==========
images
==========*/
img{
  max-width: 100%;
}

.headshots img{
  border-radius: 50%;
  width: 100%;
}

/*==========
buttons
==========*/

/*==========
layout components
==========*/


.contrast {
  width: 100%;
  background-color: #1A2930;
  color: #fff;
  margin: 2% 0;
  padding: 2%;
  position: relative;
}

.contrastText{
  padding: 10%;
}

.contrast a  {
  color: #fff;
}

.contrast img{
  width: 90%;
}

section .card{
  background-color: #1A2930;
  color: #fff;
  margin-bottom: 2vh;
}

@media (max-width: 375px){
  .card{
    margin-bottom: 2vh;
  }
}
/*==========
Navigation Bar
===========*/
nav{
}
/*==========
footer
===========*/
footer{
  margin-top: 2%;
  padding-top: 2%;
  background-color: #F7CE3E;
  color:  #0A1612;
  margin-bottom: -2%;
}

footer a{
  color:  #0A1612;
}

footer ul{
  list-style-type: none;
}

.social img{
  width: 25%;
}



